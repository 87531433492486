.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-removed)
  > td {
  background-color: #3aa9ae;
  color: #fff;
}
.tooltip {
  background-color: #fff !important;
  color: rgba(32, 31, 31, 0.9) !important;
  border-radius: 0 !important;
  border: solid 1px rgba(32, 31, 31, 0.9) !important;
  box-shadow: none;
}
.dx-datagrid-focus-overlay {
  border: none;
}
