.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty
  .dx-texteditor-label
  .dx-label {
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  border-top: 1px solid rgba(0, 0, 0, 0.23);
  border-left: none;
  border-right: none;
}
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty
  .dx-texteditor-label
  .dx-label {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty
  .dx-texteditor-label
  .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty
  .dx-texteditor-label
  .dx-label-before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  border-top: 1px solid rgba(0, 0, 0, 0.23);
}
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty
  .dx-texteditor-label
  .dx-label-after {
  border-right: 1px solid rgba(0, 0, 0, 0.23);
}
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty
  .dx-texteditor-label
  .dx-label-before {
  border-left: 1px solid rgba(0, 0, 0, 0.23);
}

.dx-editor-outlined.dx-texteditor-with-floating-label.dx-lookup-empty.dx-state-hover
  .dx-texteditor-label
  .dx-label,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-hover
  .dx-texteditor-label
  .dx-label {
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  border-top: 1px solid rgba(0, 0, 0, 0.87);
  margin: 0px;
  border-left: solid 1px transparent;
}
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-lookup-empty.dx-state-hover
  .dx-texteditor-label,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-hover
  .dx-texteditor-label {
  margin: 0px;
}

.dx-editor-outlined.dx-texteditor-with-floating-label.dx-lookup-empty.dx-state-hover
  .dx-texteditor-label
  .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-lookup-empty.dx-state-hover
  .dx-texteditor-label
  .dx-label-before,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-hover
  .dx-texteditor-label
  .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-hover
  .dx-texteditor-label
  .dx-label-before {
  border-color: rgba(0, 0, 0, 0.87);
  border-top: 1px solid rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.dx-editor-outlined.dx-texteditor-with-floating-label.dx-lookup-empty.dx-state-hover
  .dx-texteditor-label
  .dx-label-before,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-hover
  .dx-texteditor-label
  .dx-label-before {
  margin-right: -1px;
}
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-lookup-empty.dx-state-hover.dx-dropdowneditor-active
  .dx-texteditor-label
  .dx-label,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-hover.dx-dropdowneditor-active
  .dx-texteditor-label
  .dx-label
  span {
  color: rgba(0, 0, 0, 0.54);
}

.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused
  .dx-texteditor-label
  .dx-label {
  color: #3aa9ae;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-dropdowneditor-active.dx-state-focused
  .dx-texteditor-label
  .dx-label,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-dropdowneditor-active.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused
  .dx-texteditor-label
  .dx-label,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-textarea.dx-state-focused
  .dx-texteditor-label
  .dx-label,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-textarea.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label,
.dx-editor-outlined.dx-texteditor-with-label.dx-dropdowneditor-active.dx-state-focused
  .dx-texteditor-label
  .dx-label,
.dx-editor-outlined.dx-texteditor-with-label.dx-dropdowneditor-active.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label,
.dx-editor-outlined.dx-texteditor-with-label.dx-state-focused
  .dx-texteditor-label
  .dx-label,
.dx-editor-outlined.dx-texteditor-with-label.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label,
.dx-editor-outlined.dx-texteditor-with-label.dx-textarea.dx-state-focused
  .dx-texteditor-label
  .dx-label,
.dx-editor-outlined.dx-texteditor-with-label.dx-textarea.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label {
  border-bottom: 2px solid #3aa9ae;
  border-top: none;
  color: #3aa9ae;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-dropdowneditor-active.dx-state-focused
  .dx-texteditor-label
  .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-dropdowneditor-active.dx-state-focused
  .dx-texteditor-label
  .dx-label-before,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-dropdowneditor-active.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-dropdowneditor-active.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label-before,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused
  .dx-texteditor-label
  .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused
  .dx-texteditor-label
  .dx-label-before,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label-before,
.dx-editor-outlined.dx-texteditor-with-label.dx-dropdowneditor-active.dx-state-focused
  .dx-texteditor-label
  .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-label.dx-dropdowneditor-active.dx-state-focused
  .dx-texteditor-label
  .dx-label-before,
.dx-editor-outlined.dx-texteditor-with-label.dx-dropdowneditor-active.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-label.dx-dropdowneditor-active.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label-before,
.dx-editor-outlined.dx-texteditor-with-label.dx-state-focused
  .dx-texteditor-label
  .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-label.dx-state-focused
  .dx-texteditor-label
  .dx-label-before,
.dx-editor-outlined.dx-texteditor-with-label.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-label.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label-before {
  border-color: #3aa9ae;
  border-style: solid;
  border-top: 2px solid #3aa9ae;
  border-bottom: 2px solid #3aa9ae;
}
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused
  .dx-texteditor-label
  .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label-after {
  border-right: 2px solid #3aa9ae;
}
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused
  .dx-texteditor-label
  .dx-label-before,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label-before {
  border-left: 2px solid #3aa9ae;
}
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused
  .dx-texteditor-label
  .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused.dx-state-hover
  .dx-texteditor-label
  .dx-label-after {
  border-right: 2px solid #3aa9ae;
}
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-hover
  .dx-texteditor-label
  .dx-label {
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.54);
}
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-hover
  .dx-texteditor-label
  .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-hover
  .dx-texteditor-label
  .dx-label-before,
.dx-editor-outlined.dx-texteditor-with-label.dx-state-hover
  .dx-texteditor-label
  .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-label.dx-state-hover
  .dx-texteditor-label
  .dx-label-before {
  border-color: rgba(0, 0, 0, 0.87);
  border-top: 1px solid rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
}

.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-hover.dx-dropdowneditor-active
  .dx-texteditor-label
  .dx-label
  span {
  color: #3aa9ae;
}
